@charset "UTF-8";
/*@import url(https://fonts.googleapis.com/css?family=Abel);*/

html {
  font-size: 62.5%;
}
body {
  font-family: '游ゴシック', YuGothic, 'メイリオ', Verdana, 'Hiragino Kaku Gothic ProN', Meiryo, sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.6;
  letter-spacing: .1em;
  color: #191716;
  background-color: #fff;
}
a {
	text-decoration: none;
	color: #0f949b;
	&:visited, &:hover {
		color: #0f949b;
	}
	&:active {
		color: #16d6e0;
	}
	&:hover {
		text-decoration: underline;
	}
}

/* mediaquery
------------------------------------------------------------*/

$sp: 480px;  // Smartphone
$tab: 600px; // Tablet
$pc: 960px; // PC
$fpc: 1280px; // FULL PC

@mixin ssp {
	@media screen and ( max-width: ($sp)) {
		/* ===== low480px ===== */
		@content;
	}
}
@mixin sp {
	@media screen and (min-width: ($sp)) and ( max-width: ($tab)) {
		/* ===== 480px〜600px ===== */
		@content;
	}
}
@mixin tab {
	@media screen and (min-width: ($tab)) and ( max-width: ($pc)) {
		/*　===== 600px〜960px =====　*/
		@content;
	}
}
@mixin pc {
	@media screen and (min-width: ($pc)) and ( max-width: ($fpc)) {
		/*　===== 960px〜1280px =====　*/
		@content;
	}
}
@mixin fpc {
	@media screen and (min-width: ($fpc)) {
		/*　===== over1280px =====　*/
		@content;
	}
}


/* align
------------------------------------------------------------*/

h2.text-center,div.text-center  {
	margin: 20px 0;
	text-align: center;
	font-weight: bold;
}

/* layout
------------------------------------------------------------*/

img.block-center, section div.container, #skills i {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

/* font
------------------------------------------------------------*/

.abel, section h2, #skills h3, #works .works-box a .works-box-inner .button-readmore {
	font-family: 'Abel', sans-serif;
}
/*
.uppercase, section h2, #skills h3, #works .works-box a .works-box-inner .button-readmore {
  text-transform: uppercase;
}
*/

/* loading
------------------------------------------------------------*/

#loading-wrapper {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: #191816;
	z-index: 9999;
}
#loading-text {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	color: #999;
	width: 100px;
	height: 30px;
	margin: -7px 0 0 -45px;
	text-align: center;
	font-family: 'PT Sans Narrow', sans-serif;
	font-size: 20px;
}
#loading-content {
	display: block;
	position: relative;
	left: 50%;
	top: 50%;
	width: 170px;
	height: 170px;
	margin: -85px 0 0 -85px;
	border: 3px solid #F00;
}
#loading-content:after {
	content: "";
	position: absolute;
	border: 3px solid #0F0;
	left: 15px;
	right: 15px;
	top: 15px;
	bottom: 15px;
}
#loading-content:before {
	content: "";
	position: absolute;
	border: 3px solid #00F;
	left: 5px;
	right: 5px;
	top: 5px;
	bottom: 5px;
}
#loading-content {
	border: 3px solid transparent;
	border-top-color: #4D658D;
	border-bottom-color: #4D658D;
	border-radius: 50%;
	-webkit-animation: loader 2s linear infinite;
	-moz-animation: loader 2s linear infinite;
	-o-animation: loader 2s linear infinite;
	animation: loader 2s linear infinite;
}
#loading-content:before {
	border: 3px solid transparent;
	border-top-color: #D4CC6A;
	border-bottom-color: #D4CC6A;
	border-radius: 50%;
	-webkit-animation: loader 3s linear infinite;
	-moz-animation: loader 2s linear infinite;
	-o-animation: loader 2s linear infinite;
	animation: loader 3s linear infinite;
}
#loading-content:after {
	border: 3px solid transparent;
	border-top-color: #84417C;
	border-bottom-color: #84417C;
	border-radius: 50%;
	-webkit-animation: loader 1.5s linear infinite;
	animation: loader 1.5s linear infinite;
	-moz-animation: loader 2s linear infinite;
	-o-animation: loader 2s linear infinite;
}
@-webkit-keyframes loaders {
	0% {
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes loader {
	0% {
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
#content-wrapper {
	color: #FFF;
	position: fixed;
	left: 0;
	top: 20px;
	width: 100%;
	height: 100%;
}
#header {
	width: 800px;
	margin: 0 auto;
	text-align: center;
	height: 100px;
	background-color: #666;
}
#content {
	width: 800px;
	height: 1000px;
	margin: 0 auto;
	text-align: center;
	background-color: #888;
}


/* Header
------------------------------------------------------------*/

.header_container {
	width: 100%;
	margin: 0 auto;
	padding: 40px 0;
	text-align: center;
	background-color: #191816;
	img {
		@include ssp {
			width: 30%;
		}
		@include sp {
			width: 30%;
		}
		@include tab {
			width: 150px;
		}
		@include pc {
			width: 150px;
		}
		@include fpc {
			width: 150px;
		}
	}
}
.button-contact {
	font-size: 20px;
	line-height: 0;
	display: block;
	width: 20px;
	height: 20px;
	margin: 20px 20px 0 0;
	padding: 10px;
	text-align: center;
	color: #4c4c4c;
	border: 2px solid #4c4c4c;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	&:visited {
		color: #4c4c4c;
	}
	&:hover, &:active {
		color: #fff;
		background-color: #4c4c4c;
	}
}
header {
  .profile {
		width: 58rem;
		margin: 0 auto;
		padding: 15vw 0;
		position: relative;
		display: flex;
		@include ssp {
			width: 100%;
			padding: 5vh 0 10vh;
			display:block;
		}
		@include sp {
			width: 100%;
			padding: 5vh 0 10vh;
			display:block;
		}
  }
	.box {
		max-width: 70vw;
		padding: 30px;
		margin: 0 auto;
		position: relative;
		top: 50%;
		font-size: 30px;
		line-height: 1.5;
		transform: translateY(-50%);
		perspective: 400px;
	}
	.source {
		color: skyblue;
		margin: 0 auto;
	}
	.site-title {
		font-size: 18px;
		font-size: 1.8rem;
		line-height: normal;
		span {
			font-size: 42px;
			font-size: 4.2rem;
			display: block;
			color: #99a05d;
		}
	}
  .profile-icon {
		margin: 30px auto;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		border: 10px solid rgba(10, 10, 10, 0.09);
  }
  p {
		font-size: 16px;
		font-size: 1.6rem;
		line-height: 1.56em;
		display: block;
		margin: 50px 0 0 20px;
		@include ssp {
			width: 90%;
			margin: 0 auto;
			font-size: 1em;
			line-height: 1.56rem;
			display: block;
			white-space: pre-line;
		}
		@include sp {
			width: 90%;
			margin: 0 auto;
			font-size: 1em;
			line-height: 1.56rem;
			display: block;
			white-space: pre-line;
		}
	}
}
ul.back_text {
	margin: 0;
	list-style: none;
	font-size: 4vw;
	position: absolute;
	transform-style: preserve-3d;
	animation: three-dimensions-circle 30s linear infinite;
	li {
		position: absolute;
		top: 50%;
		left: 50%;
	}
}
@for $i from 1 through 12 {
	ul.back_text li:nth-child(#{$i}) {
		transform: translate(-50%, -50%) rotate(#{$i * 30 - 30}deg) translateY(-260px) rotateX(90deg);
	}
}
@keyframes three-dimensions-circle {
	0% {
		transform: rotateX(-100deg) rotate(0);
	}
	100% {
		transform: rotateX(-100deg) rotate(-360deg);
	}
}


/* main
------------------------------------------------------------*/

$ease-cb: cubic-bezier(.19,1,.22,1);

@mixin nth-trans-delay($delay_items: 7, $delay_time: 0.2s){
	@for $i from 1 through $delay_items {
		&:nth-child(#{$i}) {
			transition-delay: $delay_time * $i;
		}
	}
}
@mixin nth-ani-delay($delay_items: 7, $delay_time: 0.2s){
	@for $i from 1 through $delay_items {
		&:nth-child(#{$i}) {
			animation-delay: $delay_time * $i;
		}
	}
}
@mixin bg-overlay($bg_hex: #111, $bg_opacity: 0.6, $top_layer: 'header') {
  &:after {
		content: '';
		z-index: 0;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: rgba($bg_hex, $bg_opacity);
  }
  & > #{$top_layer}{
    z-index: 2;
    position: relative;
  }
}
.sep {
	border: 0;
	width: 3em;
	height: 1px;
	margin: 1em 0;
	background-color: rgba(255,255,255,0.4);
	@include tab {
		display: none;
	}
}
.mast {
	position: relative;
	display:flex;
	align-items: center;
	color: #4c4c4c;
	background-color: #111;
  @include bg-overlay(#111, 0.9);
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		margin: 0;
		padding: 0;
		background-size: cover;
		background-position: 50%;
	}
  &__header {
		position: absolute;
		bottom: -100px;
		padding: 5%;
		@include ssp {
			width: 100%;
			padding: 0;
			position: static;
			bottom: 0;
		}
		@include sp {
			width: 100%;
			padding: 0;
			position: static;
			bottom: 0;
		}
		@include tab {
			padding: 0 5%;
			bottom: 400px;
		}
		@include pc {
			bottom: 0;
		}
  }
	&__title {
		font-size: 1em;
		font-weight: 400;
		letter-spacing: 0.3em;
		text-transform: uppercase;
		@include ssp {
			width: 90%;
			margin: 0 auto;
			text-align: center;
		}
		@include sp {
		width: 90%;
		margin: 0 auto;
		text-align: center;
		}
	}
  // Letter animation
  &__title span {
		animation: letter-glow 0.7s 0s ease both;
	@include nth-ani-delay(25, 0.05s);
	}
	//Letter animation
	&__text {
		font-size: 1em;
		margin: 0 0 0.5em;
		line-height: 1.5;
		white-space: pre-line;
		@include ssp {
			width: 90%;
			margin: 0 auto;
			padding-bottom: 10vh;
			white-space: pre-line;
		}
		@include sp {
			width: 90%;
			margin: 0 auto;
			padding-bottom: 10vh;
			white-space: pre-line;
		}
		@include tab {
			white-space: unset;
		}
  }
  &__text span {
    animation: letter-glow 0.7s 0s ease both;
    @include nth-ani-delay(400, 0.05s);
  }
}
// Letter Glow
@keyframes letter-glow{
	0%   { opacity: 0; text-shadow: 0px 0px 1px rgba(255,255,255,0.1)}
	66%  { opacity: 1; text-shadow: 0px 0px 20px rgba(255,255,255,0.9) }
	77%   { opacity: 1;  }
	100% { opacity:0.7; text-shadow: 0px 0px 20px rgba(255,255,255,0.0)}
}


/* background
------------------------------------------------------------*/

.animation-container {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	@include ssp {
		width: 100%;
		position: absolute;
	}
	@include sp {
		width: 100%;
		position: absolute;
	}
}

.animation-container span {
  color: #191816;
  display: block;
  font-size: 18px;
  font-family: 'Helvetica';
  text-shadow: 0 0 1px white;
  position: absolute;
  user-select: none;
  pointer-events: none;
  cursor: default;
  z-index: 1;
  opacity: 0;
  will-change: transform, opacity;
  animation-timing-function: ease-out;
  animation-name: move;
}
@keyframes move {
  0% {
    opacity: 0;
    transform: translateY(100vh);
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: none;
  }
}


/* navigation
------------------------------------------------------------*/
nav.gnav {
	height: 50px;
	width: 100%;
	line-height: 50px;
	display: flex;
	border-top: 1px solid #d5c7ac;
	background-color: #191816;
	h2 {
		width: 15%;
		margin-right:1vw;
		text-align: center;
		color: #fff;
		transform: skewX(-150deg);
		border-right: 1px solid #d5c7ac;
		@include ssp {
			width: 40%;
		}
		@include sp {
			width: 40%;
		}
		span {
			display:block;
			transform: skewX(150deg);
			padding:0 10px;
		}
	}
  h2.news_nav {
    @include ssp {
      width: 50%;
    }
    @include sp {
      width: 50%;
    }
  }
	ul.gnav_content {
		width: 40%;
		display: flex;
		@include ssp {
			display: none;
		}
		@include sp {
			display: none;
		}
		@include tab {
			width: 70%;
		}
	}
	li {
		margin: 0 auto;
		&.selected_border {
			position: relative;
			&:after {
				content: "";
				height: 3%;
				width: 100%;
				position: absolute;
				top: 40px;
				left: 0;
				display: block;
				background: #d5c7ac;
				@include tab {
					top: 3.8vh;
				}
			}
			a {
				color: #d5c7ac;
				&:hover {
					color: #d5c7ac;
					padding-bottom: 0;
					border-bottom:  none;
					transition: 0;
				}
			}
		}
		a {
			color: #d5c7ac;
			text-decoration: none;
			&:hover {
				padding-bottom: 5px;
				color: #fff;
				border-bottom:  2px solid #fff;
				transition: 0.4s;
			}
		}
	}
}
nav.category_nav {
	h2 {
		@include ssp {
			width: 45%;
		}
		@include sp {
			width: 45%;
		}
	}
}

/* Anchor link
------------------------------------------------------------*/

$strokeWidth: 4px;
$strokeColor: #333;

$timing: 2.6s;
$easing: cubic-bezier(0.77, 0, 0.175, 1);

div.anchor-icon {
	width: 10%;
	height: 8vh;
	position: fixed;
	left: 2vw;
	top: 30vh;
	z-index: 1;
	@include ssp {
		width: 20%;
		height: 8vh;
		left: 5vw;
		top: 85vh;
		z-index: 1;
	}
	@include sp {
		width: 20%;
		height: 8vh;
		left: 5vw;
		top: 85vh;
		z-index: 1;
	}
	@include tab {
		width: 10%;
		height: 8vh;
		top: 21.5vh;
	}
	a {
		width: 100%;
		height: 100%;
		position: absolute;
		top: -1.5vh;
		z-index: 1;
	}
}

.scroll-down-dude:before,
.scroll-down-dude:after {
	content: '';
	display: block;
	width: 12px;
	height: 12px;
	transform: rotate(45deg);
	border-bottom: $strokeWidth solid $strokeColor;
	border-right: $strokeWidth solid $strokeColor;
}
.scroll-down-dude:before {
	//content: "____MENU";
	margin: 0 auto;
	animation: down-arrow-before $timing $easing infinite;
}
.scroll-down-dude:after {
	margin: 0 auto;
	animation: down-arrow-after $timing $easing infinite;
}
@keyframes down-arrow-before {
	50% { transform: rotate(45deg) translate(70%, 70%); }
	100% { transform: rotate(45deg) translate(70%, 70%); }
}
@keyframes down-arrow-after {
	50% {
		transform: rotate(45deg) translate(110%, 110%);
		opacity: 0;
	}
	51% {
		transform: rotate(45deg) translate(-130%, -130%);
	}
	100% {
		transform: rotate(45deg) translate(-70%, -70%);
		opacity: 1;
	}
}


/* / main
--------------------------------------------------------------
------------------------------------------------------------*/

/* index.html
------------------------------------------------------------*/

section {
	.container {
    width: 80%;
    margin: 0 auto;
		@include tab {
			width: 100%;
			padding: 60px 0 0;
		}
	}
	h2 {
		font-size: 32px;
		font-size: 3.2rem;
	}
}
#relation {
		background-color: #d5c7ac;
		@include ssp {
			background-color: #fff;
		}
		@include sp {
			background-color: #fff;
		}
	.container {
		display: -webkit-flex;
		justify-content: space-between;
		@include ssp {
			width: 100%;
			display: block;
		}
		@include sp {
			width: 100%;
			display: block;
		}
	}
	h2 {
		padding: 60px 0;
		color: #0e1003;
		font-weight: bold;
  }
	.skill-box {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		width: 32%;
		padding: 20px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		background-color: #fdfcf6;
	}
	i {
		font-size: 45px;
		font-size: 4.5rem;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		width: 80px;
		height: 80px;
		margin-bottom: 5px;
		padding-top: 15px;
		text-align: center;
		color: #fff;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
		background-color: #d6a350;
  }
	h3 {
		font-size: 24px;
		font-size: 2.4rem;
		font-weight: bold;
		margin-bottom: 10px;
		color: #100f0d;
		@include tab{
			margin-bottom: 0;
			font-size: 18px;
			font-size: 1.8rem;
		}
	}
}


/* Relation
------------------------------------------------------------*/

#relations_content {
  height: 550px;
  width: 90%;
  margin: 100px auto;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
	@include ssp {
		margin: 0 auto;
		height: auto;
		display: block;
	}
	@include sp {
		margin: 0 auto;
		height: auto;
		display: block;
	}
}
#relations_content li {
	display:inline;
  margin-bottom: 10vw;
  width: 25.33333%;
	-webkit-perspective: 500;
	-webkit-transform-style: preserve-3d;
	-webkit-transition-property: perspective;
	-webkit-transition-duration: 0.5s;
	@include ssp {
		width: 100%;
		margin-bottom: 20px;
		display: block;
		position: relative;
	}
	@include sp {
		width: 100%;
		margin-bottom: 20px;
		display: block;
		position: relative;
	}
}

#relations_content li:hover { -webkit-perspective: 5000; }

#relations_content li img {
	height: 400px;
	border:10px solid #fcfafa;
	-webkit-transform: rotateY(30deg);
	-moz-box-shadow:0 3px 10px #888;
	-webkit-box-shadow:0 3px 10px #888;
	-webkit-transition-property: transform;
	-webkit-transition-duration: 0.5s;
	@include ssp {
		width: 100%;
		height: auto;
		margin: 0 auto;
		display: block;
		border: none;
		-webkit-transform: none;
		-moz-box-shadow: 0 3px 10px #888;
		-webkit-box-shadow: none;
		-webkit-transition-property: none;
		-webkit-transition-duration: unset;
	}
	@include sp {
		width: 100%;
		height: auto;
		margin: 0 auto;
		display: block;
		border: none;
		-webkit-transform: none;
		-moz-box-shadow: 0 3px 10px #888;
		-webkit-box-shadow: none;
		-webkit-transition-property: none;
		-webkit-transition-duration: unset;
	}
	@include tab {
		width: 80%;
		height: auto;
		margin: 0 auto;
		display: block;
	}
}

#relations_content li:hover img { -webkit-transform: rotateY(0deg); }

.main_content_info {
	width:200px;
	height:180px;
	margin:-195px 0 0 55px;
	padding:20px;
	background-color:#d5c7ac;
	border:10px solid #fcfafa;
	position:absolute;
	-moz-box-shadow:0 20px 40px #888;
	-webkit-box-shadow:0 20px 40px #888;
	-webkit-transform: translateZ(30px) rotateY(30deg);
	-webkit-transition-property: transform, box-shadow, margin;
	-webkit-transition-duration: 0.5s;
	@include ssp {
		width: 100%;
		height: auto;
		margin: 0 auto;
		padding: 0;
		border: 10px solid #dddfdf;
		box-sizing: border-box;
		position: absolute;
		bottom: 0;
		-moz-box-shadow: 0 20px 40px #888;
		-webkit-box-shadow: none;
		-webkit-transform: none;
		-webkit-transition-property: unset;
		-webkit-transition-duration: 0;
	}
	@include sp {
		width: 100%;
		height: auto;
		margin: 0 auto;
		padding: 0;
		border: 10px solid #fcfafa;
		box-sizing: border-box;
		position: absolute;
		bottom: 0;
		-moz-box-shadow: 0 20px 40px #888;
		-webkit-box-shadow: none;
		-webkit-transform: none;
		-webkit-transition-property: unset;
		-webkit-transition-duration: 0;
	}
	@include tab {
		width: 50%;
		height:200px;
	}
}
#relations_content li:hover .main_content_info {
	margin:-175px 0 0 13px;
	-webkit-transform: rotateY(0deg);
	-webkit-box-shadow:0 5px 10px #888;
	@include ssp {
		margin:0;
		-webkit-transform: none;
		-webkit-box-shadow: none;
	}
	@include sp {
		margin:0;
		-webkit-transform: none;
		-webkit-box-shadow: none;
	}
}
.main_content_info h3 {
	height: 70px;
	padding-bottom:15px;
	color:#100f0d;
	font-variant: small-caps;
	font-family:Georgia,serif,Times;
	text-align:center;
	@include tab {
		height: 50px;
	}
}
.main_content_info p {
	height: 85px;
	color: #100f0d;
	padding-bottom:15px;
	@include ssp {
		width: 90%;
		margin: 0 auto;
		text-align: left;
		white-space: pre-wrap;
	}
	@include sp {
		width: 90%;
		margin: 0 auto;
		text-align: left;
		white-space: pre-wrap;
	}
	@include tab {
		padding-bottom:58px;
	}
}
.main_content_info a {
	width:80px;
	margin:0 auto;
	padding:5px 10px;
	display:block;
	color:#eee;
	text-decoration:none;
	background-color:#100f0d;
	text-align:center;
	-moz-border-radius:5px;
	-webkit-border-radius:5px;
	@include ssp {
		width: 100%;
		margin: 0 auto;
		padding: 20px 0;
		box-sizing: border-box;
	}
	@include sp {
		width: 100%;
		margin: 0 auto;
		padding: 20px 0;
		box-sizing: border-box;
	}
}
.main_content_info a:hover, .main_content_info a:focus {
	background-color:#333;
	color:#fff;
	transition: 0.5s;
	@include ssp {
		background-color:  #100f0d;
		transition: 0;
	}
	@include sp {
		background-color:  #100f0d;
		transition: 0;
	}
	@include tab {
		margin: -175px 0 0 35px;
	}
}


/* News Content
------------------------------------------------------------*/

#works {
	h2 {
		padding: 60px 0;
		font-weight: bold;
	}
	.news_box {
		width: 100%;
		display: flex;
    background: #191816;
		@include ssp {
			display: block;
		}
		@include sp {
			display: block;
		}
		.first_contents {
			width: 100%;
			height: 100%;
			display: flex;
			flex-wrap: wrap;
			@include ssp {
				display: block;
				flex-wrap: none;
			}
			@include sp {
				display: block;
				flex-wrap: none;
			}
		}
		.new_works_box {
			width: 100%;
			position: relative;
			a {
				position: relative;
				display: block;
				img {
					display: block;
					width: 100%;
					height: auto;
				}
				.new_works_box_inner {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: none;
          color: #fff;
          background-color: rgba(0, 0, 0, 0.8);
					@include ssp {
						display: block;
						width: 100%;
					}
					@include sp {
						display: block;
						width: 100%;
					}
					.new_text_center {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 84px;
            margin: auto;
            text-align: center;
            padding: 0;
					}
					h3.new_works_title {
						font-size: 16px;
						font-size: 1.6rem;
						font-weight: bold;
						margin: 0 0 5px;
						@include ssp {
							font-size: 2.6rem;
							margin: -30px auto 0;
						}
						@include sp {
							font-size: 2.6rem;
							margin: -30px auto 0;
						}
					}
					small.new_works_data {
						font-size: 10px;
						font-size: 1rem;
						display: block;
						margin-bottom: 15px;
						@include ssp {
							font-size: 15.6px;
							font-size: 1.56rem;
						}
						@include sp {
							ont-size: 15.6px;
							font-size: 1.56rem;
						}
					}
					.new_button-readmore {
						font-size: 10px;
						font-size: 1rem;
						font-weight: bold;
						display: block;
						width: 180px;
						margin: auto;
						padding: 5px 0;
						text-align: center;
						color: #191816;
						-webkit-border-radius: 4px;
						-moz-border-radius: 4px;
						border-radius: 4px;
						background-color: #d5c7ac;
						transition: 0.2s;
						&:hover {
							background-color: #ead49a;
						}
					}
				}
			}
			&:hover .new_works_box_inner {
				display: block;
				width: 100%;
			}
		}
	}
	.sub_contents {
		width: 100%;
		height: 100%;
		display: flex;
		flex-wrap: wrap;
		@include ssp {
			display: block;
			flex-wrap: none;
		}
		@include sp {
			display: block;
			flex-wrap: none;
		}
	}
	.works-box {
		width: 50%;
		height: auto;
		margin: 0;
		padding: 0;
		@include ssp {
			width: 100%;
		}
		@include sp {
			width: 100%;
		}
		a {
			position: relative;
			display: block;
			img {
				display: block;
				max-width: 100%;
				height: auto;
			}
				.works-box-inner {
					position: absolute;
					top: 0;
					left: 0;
					display: none;
					height: 100%;
					color: #fff;
					background-color: rgba(0, 0, 0, 0.8);
					@include ssp {
						display: block;
						width: 100%;
					}
					@include sp {
						display: block;
						width: 100%;
					}
					div {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						height: 50%;
						margin: auto;
						padding: 10px 20px;
					}
					h3 {
						font-size: 16px;
						font-size: 1.6rem;
						font-weight: bold;
						margin: 0 0 5px;
						@include ssp {
							margin: 0;
							font-size: 2.6rem;
						}
						@include sp {
							margin: 0;
							font-size: 2.6rem;
						}
					}
					small {
						font-size: 10px;
						font-size: 1rem;
						display: block;
						margin-bottom: 15px;
						@include ssp {
							font-size: 1.56rem;
						}
						@include sp {
							font-size: 1.56rem;
						}
					}
					.button-readmore {
						font-size: 10px;
						font-size: 1rem;
						font-weight: bold;
						display: block;
						width: 180px;
						margin: auto;
						padding: 5px 0;
						text-align: center;
						color: #191816;
						-webkit-border-radius: 4px;
						-moz-border-radius: 4px;
						border-radius: 4px;
						background-color: #d5c7ac;
						transition: 0.2s;
						&:hover {
							background-color: #ead49a;
						}
					}
				}
				&:hover .works-box-inner {
					display: block;
					width: 100%;
				}
			}
		}
		&::after {
			display: block;
			clear: both;
		content: '';
		}
	}
	#contact {
		width: 100%;
		padding: 0;
		display: flex;
		background-color: #191816;
		border-top: 1px solid #d5c7ac;
		@include ssp {
			display: block;
		}
		@include sp {
			display: block;
		}
		div.text-left-content {
			width: 33.33333%;
			padding: 60px 0;
			text-align: center;
			@include ssp {
				width: 100%;
				padding: 50px 0 0;
			}
			@include sp {
				width: 100%;
				padding: 50px 0 0;
			}
			h2 {
				color: #d5c7ac;
				font-weight: bold;
			}
		}
		ul.text-left-box {
			text-align: center;
			li {
				&.selected_border {
					a {
						padding-bottom: 0.7vh;
						border-bottom: 2.5px solid #d5c7ac;
						&:hover {
							padding-bottom: 0.7vh;
							color: #d5c7ac;
							border-bottom: 2.5px solid #d5c7ac;
							transition: 0;
						}
					}
				}
				padding: 10px 0;
				@include ssp {
					padding: 15px 0;
				}
				@include sp {
					padding: 15px 0;
				}
				a {
					width: 100%;
					text-decoration: none;
					list-style: none;
					color: #d5c7ac;
					&:hover {
						padding-bottom: 5px;
						color: #fff;
						border-bottom:  2px solid #fff;
						transition: 0.4s;
					}
				}
			}
		}
		div.text-center-content{
			width: 33.33333%;
			padding: 60px 0;
			@include ssp {
				width: 100%;
			}
			@include sp {
				width: 100%;
			}
			h2 {
				color: #d5c7ac;
				font-weight: bold;
			}
		}
		ul.text-center-box {
			margin-top: 60px;
			text-align: center;
			@include ssp {
				margin-top: 20px;
			}
			@include sp {
				margin-top: 20px;
			}
			li {
				font-size: 28px;
				font-size: 2.8rem;
				display: inline-block;
				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				margin-right: 20px;
				text-align: center;
				vertical-align: top;
			&:last-child {
				margin-right: 0;
			}
			a {
				display: block;
				width: 65px;
				height: 60px;
				color: #fff;
				list-style: none;
				text-decoration: none;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				border-radius: 50%;
				background-color: #d5c7ac;
				text-shadow: 0 1px 0 hsl(174,5%,80%), 0 2px 0 hsl(174,5%,75%), 0 3px 0 hsl(174,5%,70%), 0 4px 0 hsl(174,5%,66%), 0 5px 0 hsl(174,5%,64%), 0 6px 0 hsl(174,5%,62%), 0 7px 0 hsl(174,5%,61%), 0 8px 0 hsl(174,5%,60%), 0 0 5px rgba(0,0,0,.05), 0 1px 3px rgba(0,0,0,.2), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.2), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.3);
				i {
					transition: 0.8s;
					&:hover {
						position: relative;
						bottom: -12.5px;
						text-shadow: none;
						display: block;
					}
				}
			}
		}
	}
	.github, .skype {
		font-size: 32px;
		font-size: 3.2rem;
	}
	.github i {
		margin-left: 2px;
	}
}

/*  / index.htm
--------------------------------------------------------------
------------------------------------------------------------*/


/* catgory
------------------------------------------------------------*/

section#categoy {
	margin-bottom: 60px;
	h2{
		width: 50%;
		margin: 45px auto;
		position: relative;
		@include ssp {
			width: 100%;
		}
		@include sp {
			width: 100%;
		}
		span {
			&::after {
				content:"";
				width: 10%;
				padding-bottom: 0;
				display: block;
				position: absolute;
				border-bottom: 5px solid #d5c7ac;
				left: 45%;
			}
		}
	}
	ul.category_container {
		width: 90%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		@include ssp {
			width: 100%;
			display: block;
			justify-content: normal;
		}
		@include sp {
			width: 100%;
			display: block;
			justify-content: normal;
		}
		li {
			width: 30.33333%;
			display: inline-block;
			box-sizing: border-box;
			filter: drop-shadow(10px 10px 10px rgba(0,0,0,0.6));
			box-shadow: 10px 10px 45px -20px;
			@include ssp {
				width: 100%;
				margin-bottom: 20px;
			}
			@include sp {
				width: 100%;
				margin-bottom: 20px;
			}
			&:hover {
				img {
					transform: scale(1.05, 1.05);
					-webkit-transform: scale(1.05, 1.05);
					-moz-transform: scale(1.05, 1.05);
					-o-transform: scale(1.05, 1.05);
					-ms-transform: scale(1.05, 1.05);
					transition: 0.4s;
					opacity: 0.8;
				}
				div.category-text-box {
					opacity: 0.8;
					transition: 0.4s;
					span {
						transition: 0.4s;
					}
				}
			}
			a {
				width: 100%;
				height: auto;
				display: block;
				color: #191816;
				text-decoration: none;
				overflow: hidden;
				img {
					width: 100%;
					height: auto;
					transition: .3s ease-in-out;
				}
				div.category-text-box {
					padding: 15px;
					span {
						margin: 10px 0;
						padding: 10px 20px;
						display: inline-block;
						letter-spacing: 1.56px;
						font-size: 10px;
						font-weight: bold;
						background: #191816;
						color: #fff;
						text-align: center;
					}
					p {
						height: 50px;
						font-size: 16px;
						font-weight: bold;
					}
					button.read_more {
						width: 30px;
						height: 30px;
						margin: 10px 0;
						background: #191816;
						border: none;
						border-radius: 120px;
						text-decoration: none;
						display: inline-block;
						display: flex;
						justify-content: center;
						align-items: center;
						transition: 0.2s ease;
						i {
							color: #fff;
							font-size: 11px;
							&:before {
								content: "\f178";
								&:active {
									background: #fff;
								}
							}
						}
					}
				}
			}
		}
	}
}

/*  / catgory
--------------------------------------------------------------
------------------------------------------------------------*/


/* web_list
------------------------------------------------------------*/

section#list {
	padding: 0;
	figure.first_view_pics {
		width: 100%;
		height: 400px;
		background-image: url("../images/img_work_site.jpg");
		background-size: cover;
		background-position: center center;
		box-sizing: border-box;
		object-fit: cover;
    @include ssp {
      height: 55vw;
    }
    @include sp {
      height: 55vw;
    }
	}
	h2.text-center {
		padding: 60px 0;
	}
	div.list_box {
		padding: 0 50px 50px;
    @include ssp {
      width: 90%;
      margin: 0 auto;
      padding: 0;
    }
    @include sp {
      width: 90%;
      margin: 0 auto;
      padding: 0;
    }
		ul.list_container {
			li.list_area {
				margin-bottom: 60px;
				position: relative;
				a.list_anc {
					display: flex;
					justify-content: space-around;
					text-decoration: none;
          @include ssp {
            display: block;
          }
          @include sp {
            display: block;
          }
					&:hover {
						opacity: 0.7;
						transition: 0.2s;
					}
					div.list_image_box {
						width: 55%;
						height: 20%;
            @include ssp {
              width: 100%;
              height: 53vw;
            }
            @include sp {
              width: 100%;
              height: 53vw;
            }
						figure.list_image {
							width: 100%;
							height: 400px;
							background: url("../images/img_work_site.jpg") no-repeat center center / cover;
              @include ssp {
                width: 100%;
                height: 51vw;
              }
              @include sp {
                width: 100%;
                height: 51vw;
              }
						}
					}
					div.list_text {
						width: 40%;
						color: #191716;
						box-sizing: border-box;
						box-shadow: 10px 10px 45px -20px;
            @include ssp {
              width: 100%;
              height: 45vw;
              padding: 0 10px;
            }
            @include sp {
              width: 100%;
              height: 45vw;
              padding: 0 10px;
            }
						h3 {
							margin: 50px 0 20px;
							font-size: 32px;
							color: #191816;
              @include ssp {
                margin: 2vw 0;
                letter-spacing: -1.44px;
              }
              @include sp {
                margin: 2vw 0;
                letter-spacing: -1.44px;
              }
						}
						p {
							color: #848484;
						}
					}
				}
				ul.share_link_icon {
					width: 40%;
					margin-bottom: 10px;
					display: flex;
					position: absolute;
					bottom: 0;
					right: 0;
					li {
						width: 45px;
						height: 45px;
						margin-right: 15px;
						border: 1px dashed #858585;
						border-radius: 50%;
						opacity: 0.5;
						box-sizing: border-box;
						&:hover {
							opacity: 0.5;
							background: #d5c7ac;
							border: 1px dashed #848484;
							transition: 0.5s;
							a {
								color: #fff;
								transition: 0.5s;
							}
						}
						a {
							width: 100%;
							height: auto;
							text-align: center;
							color: #848484;
							font-size: 18px;
							margin: 0 auto;
							display: block;
							line-height: 45px;
						}
					}
				}
			}
		}
	}
}


/*  / web_list
--------------------------------------------------------------
------------------------------------------------------------*/


/* web_article_page
------------------------------------------------------------*/

section#article.mb_box {
    margin-bottom: 5vw;
	h2{
		margin: 60px 0 40px;
	}
}

.w3-image {
	max-width: 100%;
	height: auto;
}
.w3-table,
.w3-table-all {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	display: table;
}
.w3-table-all {
	border: 1px solid #ccc;
}
.w3-bordered tr,
.w3-table-all tr {
	border-bottom: 1px solid #ddd;
}
.w3-striped tbody tr:nth-child(even) {
	background-color: #f1f1f1;
}
.w3-table-all tr:nth-child(odd) {
	background-color: #fff;
}
.w3-table-all tr:nth-child(even) {
	background-color: #f1f1f1;
}
.w3-hoverable tbody tr:hover,
.w3-ul.w3-hoverable li:hover {
	background-color: #ccc;
}
.w3-centered tr th,
.w3-centered tr td {
	text-align: center;
}
.w3-table td,
.w3-table th,
.w3-table-all td,
.w3-table-all th {
	padding: 8px 8px;
	display: table-cell;
	text-align: left;
	vertical-align: top;
}
.w3-table th:first-child,
.w3-table td:first-child,
.w3-table-all th:first-child,
.w3-table-all td:first-child {
	padding-left: 16px;
}
.w3-btn,
.w3-btn-block {
	border: none;
	display: inline-block;
	outline: 0;
	padding: 6px 16px;
	vertical-align: middle;
	overflow: hidden;
	text-decoration: none;
	color: #fff;
	background-color: #000;
	text-align: center;
	cursor: pointer;
	white-space: nowrap;
}

.w3-btn:hover,
.w3-btn-block:hover,
.w3-btn-floating:hover,
.w3-btn-floating-large:hover {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.w3-btn,
.w3-btn-floating,
.w3-btn-floating-large,
.w3-closenav,
.w3-opennav,
.w3-btn-block {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.w3-btn-floating,
.w3-btn-floating-large {
	display: inline-block;
	text-align: center;
	color: #fff;
	background-color: #000;
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 0;
	border-radius: 50%;
	cursor: pointer;
	font-size: 24px;
}
.w3-btn-floating {
	width: 40px;
	height: 40px;
	line-height: 40px;
}
.w3-btn-floating-large {
	width: 56px;
	height: 56px;
	line-height: 56px;
}
.w3-disabled,
.w3-btn:disabled,
.w3-btn-floating:disabled,
.w3-btn-floating-large:disabled {
	cursor: not-allowed;
	opacity: 0.3;
}
.w3-btn.w3-disabled *,
.w3-btn-block.w3-disabled,
.w3-btn-floating.w3-disabled *,
.w3-btn:disabled *,
.w3-btn-floating:disabled * {
	pointer-events: none;
}
.w3-btn.w3-disabled:hover,
.w3-btn-block.w3-disabled:hover,
.w3-btn:disabled:hover,
.w3-btn-floating.w3-disabled:hover,
.w3-btn-floating:disabled:hover,
.w3-btn-floating-large.w3-disabled:hover,
.w3-btn-floating-large:disabled:hover {
	box-shadow: none;
}

.w3-btn-group .w3-btn {
	float: left;
	&:after {
		content:"";
		clear: both;
		display: block;
	}
}

.w3-btn-block {
  width: 100%;
}

.w3-btn-bar .w3-btn {
  box-shadow: none;
  background-color: inherit;
  color: inherit;
  float: left;
	&:after {
		content:"";
		clear: both;
		display: block;
	}
}

.w3-btn-bar .w3-btn:hover {
  background-color: #ccc;
}

.w3-badge,
.w3-tag,
.w3-sign {
	background-color: #000;
	color: #fff;
	display: inline-block;
	text-align: center;
}
.w3-badge {
  border-radius: 50%;
}
ul.w3-ul {
  list-style-type: none;
  padding: 0;
  margin: 15px 0 0;
}
ul.w3-ul li {
  padding: 6px 2px 6px 16px;
  border-bottom: 1px solid #ddd;
}
ul.w3-ul li:last-child {
  border-bottom: none;
}
.w3-tooltip,
.w3-display-container {
  position: relative;
}
.w3-tooltip .w3-text { display: none; }
.w3-tooltip:hover .w3-text { display: inline-block; }

.w3-navbar {
	list-style-type: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
}
.w3-navbar li {
	float: left;
	&:after {
		content:"";
		clear: both;
		display: block;
	}
}
.w3-navbar li a,
.w3-navitem,
.w3-navbar li .w3-btn,
.w3-navbar li .w3-input {
	display: block;
	padding: 8px 16px;
}
.w3-navbar li .w3-btn,
.w3-navbar li .w3-input {
	border: none;
	outline: none;
	width: 100%;
}
.w3-navbar li a:hover {
	color: #000;
	background-color: #ccc;
}
.w3-navbar .w3-dropdown-hover,
.w3-navbar .w3-dropdown-click {
	position: static;
}
.w3-navbar .w3-dropdown-hover:hover,
.w3-navbar .w3-dropdown-hover:first-child,
.w3-navbar .w3-dropdown-click:hover {
	background-color: #ccc;
	color: #000;
}
.w3-navbar a,
.w3-topnav a,
.w3-sidenav a,
.w3-dropdown-content a,
.w3-accordion-content a,
.w3-dropnav a {
	text-decoration: none;
}
.w3-navbar .w3-opennav.w3-right {
	float: right;
	&:after {
		content:"";
		clear: both;
		display: block;
	}
}
.w3-topnav {
	padding: 8px 8px;
}
.w3-topnav a {
  padding: 0 8px;
  border-bottom: 3px solid transparent;
  -webkit-transition: border-bottom .25s;
  transition: border-bottom .25s;
}
.w3-topnav a:hover { border-bottom: 3px solid #fff; }
.w3-topnav .w3-dropdown-hover a { border-bottom: 0; }

.w3-opennav,
.w3-closenav {
  color: inherit;
}

.w3-opennav:hover,
.w3-closenav:hover {
  cursor: pointer;
  opacity: 0.8;
}

.w3-btn,
.w3-btn-floating,
.w3-dropnav a,
.w3-btn-floating-large,
.w3-btn-block,
.w3-navbar a,
.w3-sidenav a,
.w3-pagination li a,
.w3-hoverable tbody tr,
.w3-hoverable li,
.w3-accordion-content a,
.w3-dropdown-content a,
.w3-dropdown-click:hover,
.w3-dropdown-hover:hover,
.w3-opennav,
.w3-closenav,
.w3-closebtn,
*[class*="w3-hover-"] {
	-webkit-transition: background-color .25s, color .15s, box-shadow .25s, opacity 0.25s, filter 0.25s, border 0.15s;
	transition: background-color .25s, color .15s, box-shadow .15s, opacity .25s, filter .25s, border .15s;
}
.w3-ripple:active { opacity: 0.5; }
.w3-ripple {
	-webkit-transition: opacity 0s;
	transition: opacity 0s;
}
.w3-sidenav {
	height: 100%;
	width: 200px;
	background-color: #fff;
	position: fixed;
	z-index: 1;
	overflow: auto;
}
.w3-sidenav a { padding: 4px 2px 4px 16px; }
.w3-sidenav a:hover { background-color: #ccc; }
.w3-sidenav a,
.w3-dropnav a {
	display: block;
}
.w3-sidenav .w3-dropdown-hover:hover,
.w3-sidenav .w3-dropdown-hover:first-child,
.w3-sidenav .w3-dropdown-click:hover,
.w3-dropnav a:hover {
	background-color: #ccc;
	color: #000;
}
.w3-sidenav .w3-dropdown-hover,
.w3-sidenav .w3-dropdown-click {
	width: 100%;
}
.w3-sidenav .w3-dropdown-hover .w3-dropdown-content,
.w3-sidenav .w3-dropdown-click .w3-dropdown-content {
	min-width: 100%;
}
.w3-main,
#main {
	transition: margin-left .4s;
}
.w3-modal {
	z-index: 3;
	display: none;
	padding-top: 100px;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
}
.w3-modal-content {
	margin: auto;
	background-color: #fff;
	position: relative;
	padding: 0;
	outline: 0;
	width: 600px;
}
.w3-closebtn {
  text-decoration: none;
  float: right;
  font-size: 24px;
  font-weight: bold;
  color: inherit;
}
.w3-closebtn:hover,
.w3-closebtn:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}

.w3-pagination {
	display: inline-block;
	padding: 0;
	margin: 0;
}
.w3-pagination li { display: inline; }
.w3-pagination li a {
  text-decoration: none;
  color: #000;
  float: left;
  padding: 8px 16px;
	&:after {
		content:"";
		clear: both;
		display: block;
	}
}

.w3-pagination li a:hover { background-color: #ccc; }

.w3-input-group,
.w3-group {
	margin-top: 24px;
	margin-bottom: 24px;
}
.w3-input {
	width: 100%;
	box-sizing: border-box;
	padding: 8px;
	display: block;
	border: 1px solid #808080;
}
.w3-label {
	color: #009688;
}
.w3-input:not(:valid)~.w3-validate {
	color: #f44336;
}
.w3-select {
	padding: 9px 0;
	width: 100%;
	color: #000;
	border: 1px solid transparent;
	border-bottom: 1px solid #009688;
}
.w3-select select:focus {
	color: #000;
	border: 1px solid #009688;
}
.w3-select option[disabled] { color: #009688; }
.w3-dropdown-click,
.w3-dropdown-hover {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.w3-dropdown-hover:hover .w3-dropdown-content {
  display: block;
  z-index: 1;
}
.w3-dropdown-content {
	cursor: auto;
	color: #000;
	background-color: #fff;
	display: none;
	position: absolute;
	min-width: 160px;
	margin: 0;
	padding: 0;
}

.w3-dropdown-content a {
	padding: 6px 16px;
	display: block;
}

.w3-dropdown-content a:hover { background-color: #ccc; }

.w3-accordion {
	width: 100%;
	cursor: pointer;
}
.w3-accordion-content {
	cursor: auto;
	display: none;
	position: relative;
	width: 100%;
	margin: 0;
	padding: 0;
}
.w3-accordion-content a {
	padding: 6px 16px;
	display: block;
}

.w3-accordion-content a:hover { background-color: #ccc; }

.w3-progress-container {
	width: 100%;
	height: 1.5em;
	position: relative;
	background-color: #f1f1f1;
}
.w3-progressbar {
	background-color: #757575;
	height: 100%;
	position: absolute;
	line-height: inherit;
}
input[type=checkbox].w3-check,
input[type=radio].w3-radio {
	width: 24px;
	height: 24px;
	position: relative;
	top: 6px;
}
input[type=checkbox].w3-check:checked+.w3-validate,
input[type=radio].w3-radio:checked+.w3-validate {
	color: #009688;
}
input[type=checkbox].w3-check:disabled+.w3-validate,
input[type=radio].w3-radio:disabled+.w3-validate {
	color: #aaa;
}

.w3-responsive { overflow-x: auto; }

.w3-container:after,
.w3-panel:after,
.w3-row:after,
.w3-row-padding:after,
.w3-topnav:after,
.w3-clear:after,
.w3-btn-group:before,
.w3-btn-group:after,
.w3-btn-bar:before,
.w3-btn-bar:after {
	content: "";
	display: table;
	clear: both;
}
.w3-col,
.w3-half,
.w3-third,
.w3-twothird,
.w3-threequarter,
.w3-quarter {
	float: left;
	width: 100%;
	&:after {
		content:"";
		clear: both;
		display: block;
	}
}

.w3-col.s1 { width: 8.33333%; }
.w3-col.s2 { width: 16.66666%; }
.w3-col.s3 { width: 24.99999%; }
.w3-col.s4 { width: 33.33333%; }
.w3-col.s5 { width: 41.66666%; }
.w3-col.s6 { width: 49.99999%; }
.w3-col.s7 { width: 58.33333%; }
.w3-col.s8 { width: 66.66666%; }
.w3-col.s9 { width: 74.99999%; }
.w3-col.s10 { width: 83.33333%; }
.w3-col.s11 { width: 91.66666%; }

.w3-col.s12,
.w3-half,
.w3-third,
.w3-twothird,
.w3-threequarter,
.w3-quarter {
	width: 99.99999%;
}

@media only screen and (min-width:601px) {
	.w3-col.m1 { width: 8.33333%; }
	.w3-col.m2 { width: 16.66666%; }
	.w3-col.m3, .w3-quarter { width: 24.99999%; }
	.w3-col.m4, .w3-third { width: 33.33333%; }
	.w3-col.m5 { width: 41.66666%; }
	.w3-col.m6, .w3-half { width: 49.99999%; }
	.w3-col.m7 { width: 58.33333%; }
	.w3-col.m8, .w3-twothird { width: 66.66666%; }
	.w3-col.m9, .w3-threequarter { width: 74.99999%; }
	.w3-col.m10 { width: 83.33333%; }
	.w3-col.m11 { width: 91.66666%; }
	.w3-col.m12 { width: 99.99999%; }
}

@media only screen and (min-width:993px) {
	.w3-col.l1 { width: 8.33333%; }
	.w3-col.l2 { width: 16.66666%; }
	.w3-col.l3, .w3-quarter { width: 24.99999%; }
	.w3-col.l4, .w3-third { width: 33.33333%; }
	.w3-col.l5 { width: 41.66666%; }
	.w3-col.l6, .w3-half { width: 49.99999%; }
	.w3-col.l7 { width: 58.33333%; }
	.w3-col.l8,.w3-twothird { width: 66.66666%; }
	.w3-col.l9, .w3-threequarter { width: 74.99999%; }
	.w3-col.l10 { width: 83.33333%; }
	.w3-col.l11 { width: 91.66666%; }
	.w3-col.l12 { width: 99.99999%; }
}

.w3-content {
	max-width: 980px;
	margin: auto;
}

.w3-rest { overflow: hidden; }
.w3-hide { display: none; }
.w3-show-block, .w3-show { display: block; }
.w3-show-inline-block { display: inline-block; }

@media (max-width:600px) {
	.w3-modal-content {
		margin: 0 10px;
		width: auto;
	}
	.w3-modal {
		padding-top: 30px;
	}
}
@media (max-width:768px) {
	.w3-modal-content {
		width: 500px;
	}
	.w3-modal {
		padding-top: 50px;
	}
}
@media (min-width:993px) {
	.w3-modal-content {
		width: 900px;
	}
}
@media screen and (max-width:600px) {
	.w3-topnav a {
		display: block;
	}
	.w3-navbar li:not(.w3-opennav) {
	float: none;
	width: 100%;
	}
	.w3-navbar li.w3-right {
		float: none;
	}
}

@media screen and (max-width:600px) {
	.w3-topnav .w3-dropdown-hover .w3-dropdown-content,
	.w3-navbar .w3-dropdown-click .w3-dropdown-content,
	.w3-navbar .w3-dropdown-hover .w3-dropdown-content {
		position: relative;
	}
}

@media screen and (max-width:600px) {
	.w3-topnav,
	.w3-navbar {
	text-align: center;
	}
}

@media (max-width:600px) {
	.w3-hide-small {
	display: none;
	}
}

@media (max-width:992px) and (min-width:601px) {
	.w3-hide-medium {
	display: none;
	}
}

@media (min-width:993px) {
	.w3-hide-large {
	display: none;
	}
}

@media screen and (max-width:992px) {
	.w3-sidenav.w3-collapse {
		display: none;
	}
	.w3-main {
		margin-left: 0;
		margin-right: 0;
	}
}

@media screen and (min-width:993px) {
	.w3-sidenav.w3-collapse {
	display: block;
	}
}

.w3-top,
.w3-bottom {
	position: fixed;
	width: 100%;
	z-index: 1;
}

.w3-top { top: 0; }
.w3-bottom { bottom: 0 }
.w3-overlay {
	position: fixed;
	display: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 2;
}

.w3-left { float: left; }
.w3-right { float: right; }
.w3-tiny { font-size: 10px; }
.w3-small {font-size: 12px; }
.w3-medium {font-size: 15px; }
.w3-large { font-size: 18px; }
.w3-xlarge { font-size: 24px; }
.w3-xxlarge { font-size: 36px; }
.w3-xxxlarge { font-size: 48px; }
.w3-jumbo { font-size: 64px; }

div.w3-vertical {
	word-break: break-all;
	line-height: 1;
	text-align: center;
	width: 0.6em;
}
div.w3-justify {
	.date {
		padding: 10px 10px 15px;
	}
}

.w3-left-align { text-align: left; }
.w3-right-align { text-align: right; }
.w3-justify { text-align: justify; }
.w3-center { text-align: center; }
.w3-display-topleft {
	position: absolute;
	left: 0;
	top: 0;
}
.w3-display-topright {
	position: absolute;
	right: 0;
	top: 0;
}
.w3-display-bottomleft {
	position: absolute;
	left: 0;
	bottom: 0;
}
.w3-display-bottomright {
	position: absolute;
	right: 0;
	bottom: 0;
}
.w3-display-middle {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
}
.w3-display-left {
	position: absolute;
	top: 50%;
	left: 0%;
	transform: translate(0%, -50%);
	-ms-transform: translate(-0%, -50%);
}
.w3-display-right {
	position: absolute;
	top: 50%;
	right: 0%;
	transform: translate(0%, -50%);
	-ms-transform: translate(0%, -50%);
}
.w3-display-topmiddle {
	position: absolute;
	left: 50%;
	top: 0;
	transform: translate(-50%, 0%);
	-ms-transform: translate(-50%, 0%);
}
.w3-display-bottommiddle {
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translate(-50%, 0%);
	-ms-transform: translate(-50%, 0%);
}
.w3-display-container:hover .w3-display-hover { display: block; }
.w3-display-container:hover span.w3-display-hover { display: inline-block; }
.w3-display-hover { display: none }
.w3-circle { border-radius: 50%; }
.w3-round-small { border-radius: 2px;}
.w3-round,
.w3-round-medium {
	border-radius: 4px;
}
.w3-round-large { border-radius: 8px ;}
.w3-round-xlarge { border-radius: 16px; }
.w3-round-xxlarge { border-radius: 32px; }
.w3-round-jumbo { border-radius: 64px; }
.w3-margin { margin: 16px; }
.w3-margin-0 { margin: 0; }
.w3-margin-top { margin-top: 16px; }
.w3-margin-bottom { margin-bottom: 16px; }
.w3-margin-left { margin-left: 16px; }
.w3-margin-right {margin-right: 16px; }
.w3-section {
	margin-top: 16px;
	margin-bottom: 16px;
}
.w3-padding-tiny { padding: 2px 4px; }
.w3-padding-small { padding: 4px 8px; }
.w3-padding-medium,
.w3-padding,
.w3-form {
	padding: 8px 16px;
}
.w3-padding-large {
	padding: 0 24px 12px;
	@include ssp {
		padding: 0;
	}
	@include sp {
		padding: 0;
	}
}
.w3-padding-xlarge { padding: 16px 32px; }
.w3-padding-xxlarge { padding: 24px 48px; }
.w3-padding-jumbo { padding: 32px 64px; }
.w3-padding-4 {
	padding-top: 4px;
	padding-bottom: 4px;
}
.w3-padding-8 {
	padding-top: 8px;
	padding-bottom: 8px;
}
.w3-padding-12 {
	padding-top: 12px;
	padding-bottom: 12px;
}
.w3-padding-16 {
	padding-top: 16px;
	padding-bottom: 16px;
}
.w3-padding-24 {
	padding-top: 24px;
	padding-bottom: 24px;
}
.w3-padding-32 {
	padding-top: 32px;
	padding-bottom: 32px;
}
.w3-padding-48 {
	padding-top: 48px;
	padding-bottom: 48px;
}
.w3-padding-64 {
	padding-top: 64px;
	padding-bottom: 64px;
}
.w3-padding-128 {
	padding-top: 128px;
	padding-bottom: 128px;
}

.w3-padding-0 { padding: 0; }
.w3-padding-top { padding-top: 8px; }
.w3-padding-bottom { padding-bottom: 8px; }
.w3-padding-left { padding-left: 16px; }
.w3-padding-right { padding-right: 16px; }
.w3-topbar { border-top: 6px solid #ccc; }
.w3-bottombar { border-bottom: 6px solid #ccc; }
.w3-leftbar { border-left: 6px solid #ccc; }
.w3-rightbar { border-right: 6px solid #ccc; }
.w3-row-padding { margin-top: 20px; }

.w3-row-padding,
.w3-row-padding>.w3-half,
.w3-row-padding>.w3-third,
.w3-row-padding>.w3-twothird,
.w3-row-padding>.w3-threequarter,
.w3-row-padding>.w3-quarter,
.w3-row-padding>.w3-col {
	padding: 0 8px;
}
.w3-spin {
	animation: w3-spin 2s infinite linear;
	-webkit-animation: w3-spin 2s infinite linear;
}
@-webkit-keyframes w3-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
@keyframes w3-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}
.w3-black {
	color: #fff;
	background: #191816;
}
.w3-container {
	margin-top: 20px;
	p {
		padding: 0 10px;
    @include ssp {
  		padding: 10px;
  	}
  	@include sp {
  		padding: 10px;
  	}
		a {
			height: auto;
			padding: 0 10px;
			display: block;
			color: #fff;
			text-decoration: none;
			&:hover {
				color: #fff;
				background: #d5c7ac;
			}
		}
	}
}
.w3-panel {
	padding: 0.01em 16px;
	margin-top: 16px;
	margin-bottom: 16px;
}
.w3-example {
	background-color: #f1f1f1;
	padding: 0.01em 16px;
}
.w3-code,
.w3-codespan {
	font-size: 16px;
}
.w3-code {
	line-height: 1.4;
	width: auto;
	background-color: #fff;
	padding: 8px 12px;
	border-left: 4px solid #4caf50;
	word-wrap: break-word;
}
.w3-codespan {
	color: crimson;
	background-color: #f1f1f1;
	padding-left: 4px;
	padding-right: 4px;
	font-size: 110%;
}
.w3-example,
.w3-code {
	margin: 20px 0;
}

.w3-card { border: 1px solid #ccc; }
.w3-card-2, .w3-example { box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }
.w3-card-4, .w3-hover-shadow:hover { box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }
.w3-card-8 { box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }
.w3-card-12 { box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19); }
.w3-card-16 { box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21); }
.w3-card-24 { box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22); }
.w3-animate-fading {
	-webkit-animation: fading 10s infinite;
	animation: fading 10s infinite;
}
@-webkit-keyframes fading {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes fading {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.w3-animate-opacity {
	-webkit-animation: opac 0.8s;
	animation: opac 0.8s;
}
@-webkit-keyframes opac {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes opac {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.w3-animate-top {
	position: relative;
	-webkit-animation: animatetop 0.4s;
	animation: animatetop 0.4s;
}
@-webkit-keyframes animatetop {
	from {
		top: -300px;
		opacity: 0;
	}
	to {
		top: 0;
		opacity: 1;
	}
}
@keyframes animatetop {
	from {
		top: -300px;
		opacity: 0;
	}
	to {
		top: 0;
		opacity: 1;
	}
}
.w3-animate-left {
	position: relative;
	-webkit-animation: animateleft 0.4s;
	animation: animateleft 0.4s;
}
@-webkit-keyframes animateleft {
	from {
		left: -300px;
		opacity: 0;
	}
	to {
		left: 0;
		opacity: 1;
	}
}
@keyframes animateleft {
	from {
		left: -300px;
		opacity: 0;
	}
	to {
		left: 0;
		opacity: 1;
	}
}
.w3-animate-right {
	position: relative;
	-webkit-animation: animateright 0.4s;
	animation: animateright 0.4s;
}
@-webkit-keyframes animateright {
	from {
		right: -300px;
		opacity: 0;
	}
	to {
		right: 0;
		opacity: 1;
	}
}
@keyframes animateright {
	from {
		right: -300px;
		opacity: 0;
	}
	to {
		right: 0;
		opacity: 1;
	}
}
.w3-animate-bottom {
	position: relative;
	-webkit-animation: animatebottom 0.4s;
	animation: animatebottom 0.4s;
}
@-webkit-keyframes animatebottom {
	from {
		bottom: -300px;
		opacity: 0;
	}
	to {
		bottom: 0px;
		opacity: 1;
	}
}
@keyframes animatebottom {
	from {
		bottom: -300px;
		opacity: 0;
	}
	to {
		bottom: 0;
		opacity: 1;
	}
}
.w3-animate-zoom {
	-webkit-animation: animatezoom 0.6s;
	animation: animatezoom 0.6s;
}
@-webkit-keyframes animatezoom {
	from {
		-webkit-transform: scale(0);
	}
	to {
		-webkit-transform: scale(1);
	}
}
@keyframes animatezoom {
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
}
.w3-animate-input {
	-webkit-transition: width 0.4s ease-in-out;
	transition: width 0.4s ease-in-out;
}
.w3-animate-input:focus {
	width: 100% ;
}
.w3-opacity,
.w3-hover-opacity:hover {
	filter: alpha(opacity=60);
	-webkit-backface-visibility: hidden;
}
.w3-opacity-off,
.w3-hover-opacity-off:hover {
	opacity: 1;
	filter: alpha(opacity=100);
	-webkit-backface-visibility: hidden;
}
.w3-opacity-max {
	opacity: 0.25;
	filter: alpha(opacity=25);
	-webkit-backface-visibility: hidden;
}
.w3-opacity-min {
	opacity: 0.75;
	filter: alpha(opacity=75);
	-webkit-backface-visibility: hidden;
}
.w3-greyscale-max,
.w3-grayscale-max,
.w3-hover-greyscale:hover,
.w3-hover-grayscale:hover {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}
.w3-greyscale,
.w3-grayscale {
	-webkit-filter: grayscale(75%);
	filter: grayscale(75%);
}
.w3-greyscale-min,
.w3-grayscale-min {
	-webkit-filter: grayscale(50%);
	filter: grayscale(50%);
}
.w3-sepia {
	-webkit-filter: sepia(75%);
	filter: sepia(75%);
}
.w3-sepia-max,
.w3-hover-sepia:hover {
	-webkit-filter: sepia(100%);
	filter: sepia(100%);
}
.w3-sepia-min {
	-webkit-filter: sepia(50%);
	filter: sepia(50%);
}
.w3-text-shadow {
	text-shadow: 1px 1px 0 #444;
}
.w3-text-shadow-white {
	text-shadow: 1px 1px 0 #ddd;
}
.w3-transparent {
	background-color: transparent;
}
.w3-hover-none:hover {
	box-shadow: none;
	background-color: transparent;
}


/*  / web_article_page
--------------------------------------------------------------
------------------------------------------------------------*/


/* contact
------------------------------------------------------------*/

// Grid
@mixin grid($column) {
	width:(100% / 12) * $column;
	margin:0 auto;
}
// Transition Ease
@mixin ease($time:333ms, $attr:all, $curve:ease-in-out) {
	-webkit-transition: $attr $time $curve;
	-moz-transition: $attr $time $curve;
	-o-transition: $attr $time $curve;
	-ms-transition: $attr $time $curve;
	transition: $attr $time $curve;
}
//-- Lato --//
@mixin lato-1 {font-family: 'Lato', sans-serif; font-weight:100;}
@mixin lato-1-i {@include lato-1; font-style:italic;}
@mixin lato-3 {font-family: 'Lato', sans-serif; font-weight:300;}
@mixin lato-3-i {@include lato-3; font-style:italic;}
@mixin lato-4 {font-family: 'Lato', sans-serif; font-weight:400;}
@mixin lato-4-i {@include lato-4; font-style:italic;}
@mixin lato-7 {font-family: 'Lato', sans-serif; font-weight:700;}
@mixin lato-7-i {@include lato-7; font-style:italic;}
@mixin lato-9 {font-family: 'Lato', sans-serif; font-weight:900;}
@mixin lato-9-i {@include lato-9; font-style:italic;}
// Colors
$blue:#191816;
$orange:#fd9638;
$dark-orange:darken($orange, 20%);
$grey:#d5c7ac;
$light-grey:lighten($grey, 15%);
$background:#efefef;
$field-color:#e8e9ea;


body.contact_area {
	section#hire {
		margin:60px auto 120px;
		text-align:center;
		padding:50px 0 110px;
		width:80%;
		max-width:1100px;
		@include ssp {
			margin:60px auto;
		}
		@include sp {
			margin:60px auto;
			padding: 50px 0;
		}
		h2.contact_title {
			margin-bottom:40px;
      font-weight: bold;
			@include lato-1;
		}
	}
}
// Form
form.form_text {
	color:$grey;
	font-size:62.5%;
	@include grid(7);
	@include ssp {
		width: 100%;
	}
	@include sp {
		width: 100%;
	}
	@include tab {
		width: 100%;
	}
	@include pc {
		width: 100%;
	}
	.field {
		width:100%;
		position:relative;
		margin-bottom:15px;
		$hover-label-width:18%;
		$placeholder-bumper:1.5%;
		$label-padding:18px;
		// Label
		label {
			position:absolute;
			top:0;
			left:0;
			background: $blue;
			width:100%;
			padding:($label-padding + 0px) 0;
			font-size: 1.45em;
			letter-spacing: 0.075em;
			@include ease(333ms);
			// Checkmark
			+ span {
				font-family: 'SSStandard';
				opacity: 0;
				color:white;
				display: block;
				position:absolute;
				top: 12px;
				left: 7%;
				font-size: 2.5em;
				text-shadow:1px 2px 0 $dark-orange;
				@include ease(333ms);
			}
		}
		// Fields
		input[type="text"],textarea {
			border:none;
			background:$field-color;
			width:100% - $hover-label-width - $placeholder-bumper;
			margin:0;
			padding: 20px 0 18px;
			padding-left:$hover-label-width + $placeholder-bumper;
			color:$blue;
			font-size: 1.4em;
			letter-spacing: 0.05em;
			font-size: 16px;
			transform: scale(1);
			@include ssp {
				width: 100%;
				padding: 18px 0;
				text-align: right;
				font-size: 16px;
				transform: scale(1);
			}
			@include sp {
				width: 100%;
				padding: 18px 0;
				text-align: right;
				font-size: 16px;
				transform: scale(1);
			}
			&#msg {
				height: 18px;
				resize: none;
        font: 400 14.3333px Arial;
				@include ease(333ms);
			}
			// Focus and JS Toggle Class
			&:focus,&.focused {
				outline:none;
				&#msg {
					padding-bottom:150px;
				}
				+ label {
					width:$hover-label-width;
					background: $grey;
					color: $blue;
					@include ssp {
						width: 30%;
					}
					@include sp {
						width: 30%;
					}
				}
			}
			// JS Toggle Class
			&.focused {
				+ label {
					background: $grey;
					color: $blue;
				}
			}
		}
		// Field Hover
		&:hover {
			label {
				width: $hover-label-width;
				background: $grey;
				color: #fff;
			}
		}
	}
	// Submit Button
	input[type="submit"] {
		padding: 13px 50px;
		background: none;
		color: $grey;
		border: 2px solid $grey;
		-webkit-appearance: none;
		position:relative;
		font-size: 1.4em;
		letter-spacing: 0.1em;
		font-size: 16px;
		transform: scale(1);
		@include lato-3;
		@include ease;
		&:hover {
			background: $grey;
			color: $blue;
		}
		&:focus {
			outline:none;
			color: #fff;
			border: none;
			background:$blue;
		}
	}
}

/*  / contact
--------------------------------------------------------------
------------------------------------------------------------*/


/* thanks
------------------------------------------------------------*/

section#thanks_area {
	h2{
		text-align: center;
		padding: 200px 0;
	}
}

/*  / thanks
--------------------------------------------------------------
------------------------------------------------------------*/


/* Footer
------------------------------------------------------------*/

footer {
	padding: 10px 0;
	text-align: center;
	color: #d5c7ac;
	border-top: 1px solid #d5c7ac;
	background-color: #191816;
}
